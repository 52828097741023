import END_POINT from "./constants";
import http from "./http";

export function getTaxRedistribution() {
  return http.get(`${END_POINT.TAX}`);
}

export function updateTaxRedistribution(data) {
  return http.put(`${END_POINT.TAX}`, data);
}
