import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { deleteBackground, getBackground, getDigitalFigure, getListBgTypeAllocation, getListTypeAllocation } from "api/digitaFigure.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import WalletManagementRow from "./row";
import ConditionDigital from "./condition";
import ActionDigitalFigures from "./action";
import CreateAndUpdateDigitalFigure from "./CreateAndUpdateViewBg";
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalAllocateFigure from "../components/AllocateFigure";
import ModalTransferFigure from "../components/TransferFigure";
import DetailViewBg from "./DetailViewBg";
import { ERROR_DIGITAL_FIGURE } from "../constants";
import useWithToast from "hooks/useWithToast";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";
import { masterCountries } from "api/notice";
import { getLicensors } from "api/licensors.api";

export default function DFViewBG() {
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.DIGITAL_MANAGEMENT_DF_VIEW_BG
  );
  const { showToastSuccess } = useWithToast();
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [checkedIds, setCheckedIds] = useState([]);
  const [countries, setCountries] = useState([]);
  const [listLicensors, setListLicensors] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [digitals, setDigitals] = useState([]);
  const [typeAllocation, setTypeAllocation] = useState([]);

  const [modal, setModal] = useState({
    modalAllocate: false,
    modalTransfer: false,
    modalCreateAndEdit: false,
    modalDelete: false,
    modalDetailViewBg: false,
  })

  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setDataDetail(data)
  }

  const labels = [
    "Action",
    "No.", 
    "Image", 
    "Name", 
    "LICENSOR", 
    "PROJECT",
    "Count",
    "Allocation",
  ];

  const textColor = useColorModeValue("#000000", "white");

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListDigital = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getBackground({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setDigitals(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getTypeAllocation = useCallback(
    async () => {
      try {
        const res = await getListBgTypeAllocation()
        if (res?.data?.success) {
          const data = res?.data.data;
          const allocations = data?.map((allocate) => ({
            label: allocate,
            value: allocate,
          }))
          setTypeAllocation(allocations);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
  );

  const getMasterCountries = useCallback(async () => {
    try {
      const res = await masterCountries();
      if (res?.data?.success) {
        const data = res?.data.data;
        setCountries(data);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  });

  const getListLicensors = useCallback(
    async () => {
      try {
      const { data } = await getLicensors(params);
      if (data?.success) {
        const res = data?.data;
        setListLicensors(res?.records);
      }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
  );

  useEffect(() => {
    getMasterCountries();
    getTypeAllocation()
    getListLicensors();
  }, []);

  useEffect(() => {
    getListDigital();
  }, [getListDigital]);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(digitals);
    } else {
      setCheckedIds([]);
    }
  };

  const bgFeature = (type) => {
    switch (type) {
      case "3D":
        return "#fffd77"
      case "AR":
        return "#b8ffa2"
      case "ROOM":
        return "#d096ff"
      case "HoloModel":
        return "#ffabff"
      default:
        return "#ccc"
    }
  }

    const handleDelete = async () => {
      try {
        if (checkedIds?.length) {
          const listDelete = checkedIds?.map((df) => df?.id)
          const res = await deleteBackground(listDelete);
          if (res?.data?.success) {
            showToastSuccess({
              title: "Delete Success",
            });
            toggle('modalDelete')
            setCheckedIds([])
            setParams({
              ...params,
              page: 1,
            });
          } else {
            toast({
              description: ERROR_DIGITAL_FIGURE[res?.data?.message] || ERROR_DIGITAL_FIGURE[res?.data?.messages[0]],
              status: "error",
              position: "bottom-right",
            });
          }
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px" display="flex" flexDirection="column" gap="12px">
          <Flex w="100%" gap={4} >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              DF View BG Management
            </Text>
          </Flex>
          <Flex
            justifyContent='space-between'
            alignItems='flex-end'
            flexWrap='wrap'
            gap={6}
          >
            <ConditionDigital
              isLoading={isLoading}
              setParams={setParams}
              params={params}
              checkedIds={checkedIds}
              listLicensors={listLicensors}
            />
            {isHasPermissionEdit && (
              <ActionDigitalFigures checkedIds={checkedIds} toggle={toggle} />
            )}
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" mt="16px">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            dataRow={digitals}
            isCheck
            isChecked={digitals?.length > 0 && checkedIds.length === digitals?.length}
            onChangeChecked={onChangeChecked}
          >
            <>
              {digitals?.map((item, index) => {
                return (
                  <WalletManagementRow
                    key={item?.target_hunter_id}
                    item={item}
                    index={(params.page - 1) * params?.limit + index + 1}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    toggle={toggle}
                    bgFeature={bgFeature}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {modal.modalCreateAndEdit && (
        <CreateAndUpdateDigitalFigure
          isOpen={modal.modalCreateAndEdit}
          onClose={() => toggle("modalCreateAndEdit", null)}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          countries={countries}
          listLicensors={listLicensors}
        />
      )}
      {modal?.modalDelete && (
        <ModalConfirm
          isOpen={modal?.modalDelete}
          onClose={() => toggle('modalDelete')}
          onSubmit={handleDelete}
        />
      )}
      {modal?.modalDetailViewBg && (
        <DetailViewBg
          isOpen={modal?.modalDetailViewBg}
          onClose={() => toggle('modalDetailViewBg')}
          item={dataDetail}
          bgFeature={bgFeature}
        />
      )}
      {modal?.modalAllocate && (
        <ModalAllocateFigure
          isOpen={modal?.modalAllocate}
          onClose={() => toggle('modalAllocate')}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          typeAllocation={typeAllocation}
          type="viewBg"
          title="ALLOCATE DF VIEW BG"
          titleSelect="Selected DF View BG"
        />
      )}
      {modal?.modalTransfer && (
        <ModalTransferFigure
          isOpen={modal?.modalTransfer}
          onClose={() => toggle('modalTransfer')}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          type="viewBg"
          title="Transfer DF View BG"
          selectTitle="Selected DF View BG"
          columnTitle="DF View BG"
        />
      )}
    </Flex>
  );
}
