import { Box, Flex, Heading, Spacer } from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";

export default function ContributionConditions({
  onSave,
  isEdit,
  setIsEdit,
  isHasPermissionEdit,
  isLoading,
}) {
  return (
    <Flex w="100%" flexDirection="column" mb="4">
      <Flex w="100%" alignItems="center" mb="2">
        <Box>
          <Heading size="sm" textAlign="left">
            Contribution Conditions
          </Heading>
        </Box>
        <Spacer />
        {isHasPermissionEdit && (
          <Box mr="2">
            {isEdit ? (
              <ButtonType
                text="SAVE"
                w="80px"
                fontSize="12px"
                onClick={() => onSave()}
                isLoading={isLoading}
              />
            ) : (
              <ButtonType
                text="EDIT"
                w="80px"
                fontSize="12px"
                onClick={() => setIsEdit(true)}
                isLoading={isLoading}
              />
            )}
          </Box>
        )}
      </Flex>
      <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
        <Box flex="1">
          <FormInput
            isDisabled={!isEdit}
            label="Min. Reinvestment Rate (BCoin2) "
            step={1}
            name={`min_reinvestment_rate`}
            type="text"
            isRequired
          />
        </Box>
        <Box flex="1" ml="4">
          <FormInput
            isDisabled={!isEdit}
            label="Daily min. spend (BCoin2)"
            step={1}
            name={`daily_min_spend`}
            type="text"
            isRequired
          />
        </Box>
      </Flex>
    </Flex>
  );
}
