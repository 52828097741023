import {
  Flex,
  Icon,
  Input,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EditIcon } from "components/Icons/EditIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { clampPositiveInteger, formatNumberWithCommas } from "utils/number";
import { TAX_TABLE_KEY } from "./constants";

export default function TaxRedistributionRow({
  isEditMode,
  data = {},
  tableType,
  handleOpenUpdateMode,
  handleUpdateTaxRedistribution,
  isHasPermissionEdit,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [dataEdit, setDataEdit] = useState({ ...data });

  const showReinvestment = useMemo(() => {
    if (tableType === TAX_TABLE_KEY.CUMULATIVE_CONSUMPTION_POINTS) {
      if (data?.max_value >= 5e6) return "5,000,000+";

      return `${formatNumberWithCommas(
        data?.min_value
      )} - ${formatNumberWithCommas(data?.max_value)}`;
    }

    return `${data?.min_value} - ${data?.max_value}%`;
  }, [data, tableType]);

  const handleChangeNftField = (e, key) => {
    const valueField = clampPositiveInteger(e.target.value, 0, 9999);
    if (key) {
      setDataEdit((preState) => ({
        ...preState,
        [key]: valueField,
      }));
    }
  };

  const onUpdateValueNft = (dataEdit) => {
    if (dataEdit && handleUpdateTaxRedistribution) {
      handleUpdateTaxRedistribution?.(dataEdit, tableType);
    }
  };

  const renderTdNft = useCallback(
    (key) => {
      if (isEditMode) {
        return (
          <Input
            color="#000000"
            onChange={(e) => handleChangeNftField(e, key)}
            value={dataEdit?.point}
            type="text"
            maxW="250px"
          />
        );
      }
      return (
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {data?.point}
        </Text>
      );
    },
    [isEditMode, dataEdit]
  );

  useEffect(() => {
    if (data && Object.values(data)?.length) {
      setDataEdit({ ...data });
    }
  }, [data]);

  return (
    <Tr>
      <Td w="100px">
        {isHasPermissionEdit && (
          <Flex gap={6}>
            {isEditMode ? (
              <Icon
                fontSize="30px"
                borderRadius="8px"
                cursor="pointer"
                bg="#07CF84"
                color="white"
                fontWeight={"bold"}
                as={CheckIcon}
                onClick={() => onUpdateValueNft(dataEdit)}
              />
            ) : (
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => handleOpenUpdateMode()}
              />
            )}
          </Flex>
        )}
      </Td>
      <Td w="500px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {showReinvestment}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {renderTdNft("point")}
        </Text>
      </Td>
    </Tr>
  );
}
