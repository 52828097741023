import * as yup from "yup";

const schemaContributionConditions = yup.object().shape({
  min_reinvestment_rate: yup
    .string()
    .test(
      "is-valid-format",
      "Only numbers and a single decimal point are allowed",
      (value) => {
        if (!value) return true;
        return /^(?!.*[+\-eE])\d*\.?\d*$/.test(value);
      }
    )
    .test(
      "min",
      "Number must be at least 0",
      (value) => !value || Number(value) >= 0
    )
    .test(
      "max",
      "Number must be less than or equal to 1",
      (value) => !value || Number(value) <= 1
    ),

  daily_min_spend: yup
    .string()
    .test(
      "is-valid-format",
      "Only numbers and a single decimal point are allowed",
      (value) => {
        if (!value) return true;
        return /^(?!.*[+\-eE])\d*\.?\d*$/.test(value);
      }
    )
    .test(
      "min",
      "Number must be at least 0",
      (value) => !value || Number(value) >= 0
    )
    .test(
      "max",
      "Number must be less than or equal to 9999",
      (value) => !value || Number(value) <= 9999
    ),
});

export default schemaContributionConditions;
