import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAdminWalletApi, updateAdminWalletApi } from "api/wallet.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormInput from "components/form/FormInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { decryptKey, encryptKey } from "utils/encrypt-key";
import ValidateShowPassword from "views/SettingSystem/PaymentSystem/ValidateShowPassword";
import { DEFAULT_KEY, ENCRYPT_KEY, PRIVATE_KEY } from "./constants";
import schemaAdminWallet from "./schema";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";
import { convertStringSnake } from "utils/string";

export default function AdminWalletPage() {
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.SETTING_ADMIN_WALLET
  );
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    resolver: yupResolver(schemaAdminWallet()),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;
  const { isOpen, onOpen, onClose } = useDisclosure();

  // STATE
  const [isEdit, setIsEdit] = useState(false);
  const [showKeyType, setShowKeyType] = useState(null);
  const [isShowKey, setIsShowKey] = useState({
    [PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY]: false,
    [PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY]: false,
    [PRIVATE_KEY.AW_ENCRYPT_KEY]: false,
    [PRIVATE_KEY.AW_ENCRYPT_IV]: false,
  });

  const openModalPassword = (type) => {
    if (!type) return;
    setShowKeyType(type);

    if (isShowKey[type]) {
      setIsShowKey({
        ...isShowKey,
        [type]: false,
      });
    } else onOpen();
  };

  const handleOpenEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEdit(true);
  };

  const onSubmit = async (_, e) => {
    e.preventDefault();
    setShowKeyType(PRIVATE_KEY.SUBMIT);
    onOpen();
  };

  const handleSave = async (dataPassword) => {
    try {
      const values = watch();
      const params = {
        update_infos: Object.keys(values)
          ?.map((type) => {
            if (
              type === PRIVATE_KEY.AW_ENCRYPT_KEY ||
              type === PRIVATE_KEY.AW_ENCRYPT_IV ||
              !values[type] ||
              values[type] === DEFAULT_KEY
            ) {
              return null;
            }

            // Encrypt key
            const keyValue = ENCRYPT_KEY.includes(type)
              ? encryptKey(
                  values[type],
                  values[PRIVATE_KEY.AW_ENCRYPT_KEY],
                  values[PRIVATE_KEY.AW_ENCRYPT_IV]
                )
              : values[type];
            return {
              type: type,
              value: keyValue,
            };
          })
          .filter((item) => item), // only take fields with values
        ...dataPassword,
      };
      const { data } = await updateAdminWalletApi(params);
      if (data?.success) {
        toast({
          title: "Admin Wallet Information Has Been Saved Successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        onClose();
        setIsEdit(false);
        setShowKeyType(null);
      } else {
        toast({
          description: data?.message
            ? convertStringSnake(data?.message)
            : convertStringSnake(data?.messages[0]),
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message
          ? convertStringSnake(error?.message)
          : convertStringSnake(error?.messages[0]),
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleSubmitPassword = async (data) => {
    if (!data) return;

    // ON SAVE
    if (showKeyType === PRIVATE_KEY.SUBMIT) {
      handleSave(data);
      return;
    }

    // GET PRIVATE KEY
    const params = {
      sensitive_types: [showKeyType],
      ...data,
    };
    // Key need encrypt
    if (ENCRYPT_KEY.includes(showKeyType)) {
      params.sensitive_types = [
        showKeyType,
        PRIVATE_KEY.AW_ENCRYPT_KEY,
        PRIVATE_KEY.AW_ENCRYPT_IV,
      ];
    }

    const { success } = await getInitData(params);
    if (success) {
      setIsShowKey({
        ...isShowKey,
        [showKeyType]: true,
      });
      onClose();
      setShowKeyType(null);
    }
  };

  const getInitData = async (params = {}) => {
    try {
      const { data } = await getAdminWalletApi(params);
      const { success, data: dataWallet, message, messages } = data;

      if (success && Array.isArray(dataWallet)) {
        dataWallet?.forEach((item) => {
          // Decrypt key
          if (ENCRYPT_KEY.includes(item?.type) && item?.value) {
            const encryptKey = dataWallet.find(
              (item) => item.type === PRIVATE_KEY.AW_ENCRYPT_KEY
            )?.value;
            const encryptIV = dataWallet.find(
              (item) => item.type === PRIVATE_KEY.AW_ENCRYPT_IV
            )?.value;

            setValue(
              item?.type,
              decryptKey(item?.value, encryptKey, encryptIV)
            );
            return;
          }
          setValue(item?.type, item?.value || "");
        });

        return { success: true };
      } else throw new Error(message || messages[0]);
    } catch (error) {
      toast({
        description: error?.message
          ? convertStringSnake(error?.message)
          : convertStringSnake(error?.messages[0]),
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getInitData();
    return () => {
      setIsEdit(false);
    };
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <FormProvider {...form}>
          <form
            id="admin-wallet-form"
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%" }}
          >
            <CardHeader px="22px" mb="12px">
              <Flex
                w="100%"
                gap={4}
                direction={{
                  base: "column",
                  md: "row",
                }}
                justifyContent={{
                  base: "flex-start",
                  md: "space-between",
                }}
                alignItems="center"
                pb="20px"
                borderBottom="1px solid #EEEFF2"
              >
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                >
                  Admin Wallet
                </Text>
                {isHasPermissionEdit && (
                  <Flex>
                    {isEdit ? (
                      <ButtonType
                        type="submit"
                        form="admin-wallet-form"
                        text="SAVE"
                        w="100px"
                        fontSize="16px"
                        fontWeight="700"
                        isLoading={isSubmitting}
                      />
                    ) : (
                      <ButtonType
                        type="button"
                        text="EDIT"
                        w="100px"
                        fontSize="16px"
                        fontWeight="700"
                        onClick={handleOpenEdit}
                      />
                    )}
                  </Flex>
                )}
              </Flex>
            </CardHeader>
            <CardBody overflowX="auto" p="22px">
              <Flex direction="column" w="100%" gap={6}>
                <FormInput
                  maxW="100%"
                  name="AW_RECIPIENT_COLD_WALLET_ADDRESS"
                  label={"Recipient Cold Wallet"}
                  readOnly={!isEdit}
                />
                <FormInput
                  maxW="100%"
                  name="AW_PAYER_HOT_WALLET_ADDRESS"
                  label={"Payer Hot Wallet"}
                  readOnly={!isEdit}
                />
                <FormInput
                  type="password"
                  defaultValue={DEFAULT_KEY}
                  renderRight
                  maxW="100%"
                  name="AW_PAYER_HOT_WALLET_PRIVATE_KEY"
                  label={"Payer Hot Wallet Private Key"}
                  isShowPassword={false}
                  showPassword={
                    isShowKey[PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY]
                  }
                  handleShowPass={() =>
                    openModalPassword(
                      PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY
                    )
                  }
                  onChange={(e) => {
                    if (
                      !isShowKey[PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY]
                    ) {
                      openModalPassword(
                        PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY
                      );
                      return;
                    }
                    setValue("AW_PAYER_HOT_WALLET_PRIVATE_KEY", e.target.value);
                  }}
                  paddingRight={"60px"}
                  readOnly={!isEdit}
                />
                <FormInput
                  maxW="100%"
                  name="AW_NFT_HOLDER_WALLET_ADDRESS"
                  label={"NFT Holder's Address"}
                  readOnly={!isEdit}
                />
                <FormInput
                  type="password"
                  defaultValue={DEFAULT_KEY}
                  renderRight
                  maxW="100%"
                  name="AW_NFT_HOLDER_PRIVATE_KEY"
                  label={"NFT Holder's Private Key"}
                  isShowPassword={false}
                  showPassword={
                    isShowKey[PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY]
                  }
                  handleShowPass={() =>
                    openModalPassword(PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY)
                  }
                  onChange={(e) => {
                    if (!isShowKey[PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY]) {
                      openModalPassword(PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY);
                      return;
                    }
                    setValue("AW_NFT_HOLDER_PRIVATE_KEY", e.target.value);
                  }}
                  paddingRight={"60px"}
                  readOnly={!isEdit}
                />
                <FormInput
                  maxW="100%"
                  name="AW_PAYMASTER_ADDRESS_BICONOMI"
                  label={"Paymaster Address (Biconomy)"}
                  readOnly={!isEdit}
                />
              </Flex>
            </CardBody>
          </form>
        </FormProvider>
      </Card>
      {isOpen && (
        <ValidateShowPassword
          isOpen={isOpen}
          onClose={onClose}
          handleSubmitPassword={handleSubmitPassword}
        />
      )}
    </Flex>
  );
}
