import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import RowRoomSetup from "./row";
import TableCustom from "components/Tables/CustomTable";
import bannerDefault from 'assets/img/banner-event-default.png'
import React, { useEffect, useState } from "react";
import SetPosition from "../SetPosition";
import ButtonType from "components/Button";
import { updateRoomBg } from "api/digitaFigure.api";
import { ERROR_CREATE_EVENT } from "views/Event/EventManagement/constant";
import ModalConfirm from "./ModalConfirm";

const labels = [
  "Pos ID", 
  "Size", 
  "Item Type",
];

export default function RoomSetup({
  isOpen,
  onClose,
  toggle,
  modal,
  listTypeRoom,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [listPosition, setListPosition] = useState(dataDetail?.room_setup || [])
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [isPositionsRendered, setIsPositionsRendered] = useState(false);
  const [dataEdit, setDataEdit] = useState(null)
  const toast = useToast();

  // Function for event click to image
  const handleClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const imgWidth = rect.width;
    const imgHeight = rect.height;

    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    // Calculate coordinates in the new coordinate system (0, 0) in the center of the image
    const x = (offsetX - imgWidth / 2) / (imgWidth / 2);  // Calculate x in proportion
    const y = -(offsetY - imgHeight / 2) / (imgHeight / 2); // Invert the y-axis

    setCoordinates({ x: x.toFixed(2), y: y.toFixed(2) });
    toggle('modalPosition', dataDetail)
  };

  const renderPositions = () => {
    const imgBanner = document.getElementById("imgBanner");
    if (imgBanner) {
      return listPosition.map((item, index) => {
        const rect = imgBanner?.getBoundingClientRect() 
        const imgWidth = rect.width;
        const imgHeight = rect.height;
  
        const posX = (imgWidth / 2) + ((imgWidth / 2) * item?.position_x);
        const posY = (imgHeight / 2) - ((imgHeight / 2) * item?.position_y);
  
        return (
          <Box
            key={index}
            position="absolute"
            left={`${posX}px`}
            top={`${posY}px`}
            transform={`translate(-50%, -50%)`}
            width={`${(500 * item?.size) / 100}px`}
            height={`${(500 * item?.size) / 100}px`}
            backgroundColor={item?.type === "DIGITAL_FIGURE" ? "rgb(161, 248, 153, 0.4)" : "rgb(156, 190, 235, 0.4)"}
            border={item?.type === "DIGITAL_FIGURE" ? "2px solid #36a72b" : "2px solid #2c69b9" }
            onClick={(e) => {
              if (e.detail === 2) {
                toggle('modalConfirmPosition', dataDetail)
                setDataEdit(item)
              }
            }}
          />
        );
      });
    }
    return;
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsPositionsRendered(true);
      }, 500);
    } else {
      setIsPositionsRendered(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isPositionsRendered) {
      renderPositions();
    }
  }, [isPositionsRendered, listPosition]);

  const handleSave = async () => {
    const dataSubmit = {
      ...dataDetail,
      room_setup: listPosition
    }
    const res = await updateRoomBg(dataDetail?.id, dataSubmit);
    if (res?.data?.success) {
      toast({
        title: "Updated Room Successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      handleCreateOrUpdateSuccess()
      onClose()
    } else {
      toast({
        description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1200px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          ROOM SETUP
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" display="flex" direction="row" gap="12px">
          <Flex direction='column'>
            <Box
              position='relative'
              overflow='hidden'
            >
              <Image
                src={dataDetail?.image_url ||bannerDefault}
                minWidth="800px"
                minHeight="450px"
                id="imgBanner"
                alt='image_url'
                style={{ maxWidth: "100%", height: "auto", display: "block", margin: "0 auto"}}
                onClick={handleClick}
              />
              {renderPositions()}
            </Box>
          </Flex>
          {listPosition?.length > 0 && (
            <Flex maxH="500px" w="100%">
              <TableCustom
                labels={labels}
                dataRow={listPosition}
                isCheck={false}
              >
                <>
                  {listPosition?.map((item, index) => {
                    return (
                      <RowRoomSetup
                        key={index}
                        item={item}
                        index={index}
                      />
                    );
                  })}
                </>
              </TableCustom>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            onClick={handleSave}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {modal?.modalPosition && (
        <SetPosition
          isOpen={modal?.modalPosition}
          onClose={() => {
            setDataEdit(null)
            toggle('modalPosition', dataDetail)
          }}
          listTypeRoom={listTypeRoom}
          setListPosition={setListPosition}
          listPosition={listPosition}
          coordinates={coordinates}
          dataEdit={dataEdit}
        />
      )}
      {modal?.modalConfirmPosition && (
        <ModalConfirm
          isOpen={modal?.modalConfirmPosition}
          onEdit={() => {
            toggle('modalPosition', dataDetail)
            toggle('modalConfirmPosition', dataDetail)
          }}
          onClose={() => {
            setDataEdit(null)
            toggle('modalConfirmPosition', dataDetail)
          }}
          onSubmit={() => {
            let listPos = []
            listPosition?.forEach((pos) => {
              if (Number(pos?.pos) !== Number(dataEdit?.pos) && pos?.pos !== dataEdit?.pos) {
                listPos.push(pos)
              } 
            })
            setListPosition(listPos)
            setDataEdit(null)
            toggle('modalConfirmPosition', dataDetail)
          }}
        />
      )}
    </Modal>
  );
}
