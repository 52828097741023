import {
  Box,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getTaxRedistribution,
  updateTaxRedistribution,
} from "api/tax-redistribution.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableCustom from "components/Tables/CustomTable";
import { PERMISSIONS } from "constants/permissions";
import { usePermission } from "hooks/usePermission";
import useWithToast from "hooks/useWithToast";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsClock } from "react-icons/bs";
import { convertStringSnake } from "utils/string";
import { TAX_REDISTRIBUTION_TABLE, TAX_TABLE_KEY } from "./constants";
import ContributionConditions from "./ContributionConditions";
import schemaContributionConditions from "./ContributionConditions/schema";
import TaxRedistributionRow from "./row";

export default function TaxRedistribution() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.SETTING_TAX);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess, showToastFail } = useWithToast();

  const [isLoading, setIsLoading] = useState(false);
  const [taxData, setTaxData] = useState(TAX_REDISTRIBUTION_TABLE);
  const [isEdit, setIsEdit] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schemaContributionConditions),
    defaultValues: {
      bonusPasses: [],
    },
  });

  const handleShowToast = (res) => {
    if (res?.data?.success) {
      showToastSuccess({
        title: "TAX Redistribution updated successfully!",
      });
    } else {
      showToastFail({
        title: "Failed to update TAX Redistribution.",
      });
    }
  };

  const handleSaveConditions = () => {
    methods.handleSubmit(async () => {
      setIsLoading(true);
      const data = methods.getValues();
      try {
        const res = await updateTaxRedistribution({
          bcoin2_contribution_condition: {
            ...data,
            min_reinvestment_rate: data?.min_reinvestment_rate || 0,
            daily_min_spend: data?.daily_min_spend || 0,
          },
        });
        handleShowToast(res);
        setIsEdit(false);
        setRefetch((prev) => !prev);
      } catch (error) {
        toast({
          description: convertStringSnake(error?.message || error?.messages[0]),
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const handleUpdateTaxRedistribution = async (data, tableType) => {
    setIsLoading(true);
    try {
      const key =
        tableType === TAX_TABLE_KEY.CUMULATIVE_CONSUMPTION_POINTS
          ? TAX_TABLE_KEY.CUMULATIVE_CONSUMPTION_POINTS
          : "reinvestment_rate_points";
      const params = {
        [key]: [data],
      };
      const res = await updateTaxRedistribution(params);
      handleShowToast(res);
      setIdRowEdit(null);
      setRefetch((prev) => !prev);
    } catch (error) {
      toast({
        description: convertStringSnake(error?.message || error?.messages[0]),
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getReinvestmentRatePoints = (points, type) =>
    points.filter((p) => p?.reinvestment_type === type);

  const setTaxDataByKey = (prevState, data) =>
    prevState.map((item) => {
      const updatedData = { ...item };

      switch (item.key) {
        case TAX_TABLE_KEY.REINVESTMENT_RATE_POINTS_TOTAL:
          updatedData.dataTable = getReinvestmentRatePoints(
            data.reinvestment_rate_points,
            "TOTAL"
          );
          break;

        case TAX_TABLE_KEY.REINVESTMENT_RATE_POINTS_WEEKLY:
          updatedData.dataTable = getReinvestmentRatePoints(
            data.reinvestment_rate_points,
            "WEEKLY"
          );
          break;

        case TAX_TABLE_KEY.CUMULATIVE_CONSUMPTION_POINTS:
          updatedData.dataTable = data.cumulative_consumption_points;
          break;

        default:
          break;
      }

      return updatedData;
    });

  const fetchTaxRedistribution = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTaxRedistribution();
      const { success, data: dataTax, message, messages } = data;

      if (success && dataTax) {
        setTaxData((prevState) => setTaxDataByKey(prevState, dataTax));
        methods.reset({
          ...dataTax?.bcoin2_contribution_condition,
        });
      } else throw new Error(message || messages[0]);
    } catch (error) {
      toast({
        description: convertStringSnake(error?.message || error?.messages[0]),
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTaxRedistribution();
  }, [refetch]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex flexDirection={"column"} gap={"30px"} w="100%">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                whiteSpace="nowrap"
              >
                TAX Redistribution
              </Text>
            </Flex>

            <Flex align={"center"}>
              <Box flex="1">
                <Heading size="sm" textAlign="left">
                  BCoin2 distribution timing (JPT)
                </Heading>
              </Box>
              <Box flex="1" ml="4">
                <InputGroup>
                  <Input
                    color="#000000"
                    value={"04:00"}
                    type={"text"}
                    isReadOnly
                    right={"abc"}
                  />
                  <InputRightElement width="4.5rem">
                    <Icon
                      as={BsClock}
                      cursor="pointer"
                      w="24px"
                      h="24px"
                      color={"#b3b3b3"}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>

            <FormProvider {...methods} w="100%">
              <form
                style={{
                  width: "100%",
                  minWidth: "600px",
                  overflowX: "auto",
                }}
              >
                <ContributionConditions
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  onSave={handleSaveConditions}
                  isHasPermissionEdit={isHasPermissionEdit}
                  isLoading={isLoading}
                />
              </form>
            </FormProvider>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" flexDirection="column" gap="40px">
          {taxData?.map((item) => (
            <Flex key={item.key} flexDirection={"column"} gap={"20px"} w="100%">
              <Box flex="1" paddingInline={"20px"}>
                <Heading size="sm" textAlign="left">
                  {item.title}
                </Heading>
              </Box>
              <TableCustom
                labels={item.labels}
                isLoading={isLoading}
                dataRow={item?.dataTable}
                isCheck={false}
              >
                <>
                  {item?.dataTable?.map((data) => {
                    return (
                      <TaxRedistributionRow
                        key={data?.id}
                        tableType={item.key}
                        data={data}
                        isEditMode={idRowEdit && idRowEdit === data.id}
                        handleOpenUpdateMode={() => setIdRowEdit(data.id)}
                        handleUpdateTaxRedistribution={
                          handleUpdateTaxRedistribution
                        }
                        isHasPermissionEdit={isHasPermissionEdit}
                      />
                    );
                  })}
                </>
              </TableCustom>
            </Flex>
          ))}
        </CardBody>
      </Card>
    </Flex>
  );
}
