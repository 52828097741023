import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import FormLabelComponent from "components/form/FormLabel";
import FormCheckbox from "components/form/FormCheckbox";
import styled from "styled-components";
import { FEATURES_LIST, TRANS_LABEL } from "../../constants";
import FormSelect from "components/form/FormSelect";
import FormTextAria from "components/form/FormTextAria";
import ModalSelectCountries from "views/Notice/createAndUpdate/ModalSelectCountries";
import { ERROR_CREATE_EVENT } from "views/Event/EventManagement/constant";
import { createlDigitalFigure, importCSVSerial, updateDigitalFigure } from "api/digitaFigure.api";
import ItemCSV from "../ModalImportCode/itemCSV";
import SelectGroupLicensor from "views/DigitalFigure/components/SelectGroupLicensor";

const TextError = styled.div`
  padding: 0;
  color: #E53E3E;
  font-size: 16px;
`

export default function CreateAndUpdateDigitalFigure({
  isOpen,
  onClose,
  detailFg,
  handleCreateOrUpdateSuccess,
  countries,
  listLicensors,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [loadingImg, setLoadingImg] = useState({
    image_url: false,
    asset_url: false,
  });
  const [listModeType, setListModeType] = useState(() => {
    return FEATURES_LIST?.reduce((a, v) => ({ ...a, [v.value]: detailFg?.[v?.value] ? true : false }), {}) 
  });
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine(trans)),
  });
  const {
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = form;

  const { 
    isOpen: isOpenSelectCountry, 
    onOpen: onOpenSelectCountry, 
    onClose: onCloseSelectCountry,
  } = useDisclosure();

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "csvHolo",
  });

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return detailFg?.id;
  }, [detailFg]);

  useEffect(() => {
    if (detailFg) {
      reset({
        ...detailFg,
      });
    } else {
      reset();
    }
  }, [detailFg]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      name: data?.name,
      description: data?.description,
      count: data?.count,
      is_ar: listModeType?.is_ar,
      is_3d: listModeType?.is_3d,
      is_room: listModeType?.is_room,
      is_holo_model: listModeType?.is_holo_model,
      country_targets: data?.specify_target_countries ? data?.country_targets : [],
      image_url: data?.image_url,
      storage_link: data?.asset_url,
      licensor_id: data?.project,
      digital_figure_codes: data?.serial_code,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp,
        }
      ],
    }
    try {
      if (idEdit) {
        const res = await updateDigitalFigure(detailFg?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Digital Figure Edited Successfully.",
            description: "Digital Figure Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createlDigitalFigure(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Digital Figure Created Successfully.",
            description: "Digital Figure Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, type) => {
    setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    const image = event[0];
    if (type === 'asset_url') {
      const regex =  /\.(jpg|jpeg|png|fbx|JPG|JPEG|PNG|FBX)$/
      await uploadImageToServer(image, toast, setValue, type, setNameImage, regex, false);
      setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    } else {
      await uploadImageToServer(image, toast, setValue, type, setNameImage);
      setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    }
  };

   const handleDragImage = async (file, type) => {
    setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    if (type === 'asset_url') {
      await uploadImageToServer(image, toast, setValue, type, setNameImage, /\.(jpg|jpeg|png]fbx|JPG|JPEG|PNG)$/, false);
      setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    } else {
      await uploadImageToServer(image, toast, setValue, type, setNameImage);
      setLoadingImg(prev => ({ ...prev, [type]: !prev[type] }))
    }
  };

  const deleteImage = (type) => {
    setValue(type, null)
  }

  const handleOnChangeCSV = async (files) => {
    removeCSV(0);
    if (files && files?.length > 0) {
      try {
        const formData = new FormData();
        formData.append("file", files[0]);
        const dataImport = await importCSVSerial(formData)
        
        if (dataImport?.status === 200) {
          reset({
            ...getValues(),
            serial_code: dataImport?.data?.data?.data_file,
            count: dataImport?.data?.data?.count,
          })
          clearErrors("serial_code")
          appendCSV(files[0]);
        } else {
          appendCSV({
            ...files[0],
            isError: dataImport?.data?.messages[0]
          });
          reset({
            ...getValues(),
            count: '0',
          })
        }
      } catch (error) {
        toast({
          description: `${error}`,
          status: "error",
          position: "top",
        });
      }
    }
  };

  useEffect(() => {
    if (detailFg?.id) {
      const itemJP = detailFg?.translations?.find(
        (item) => item?.lang_key === "JP"
      );

      reset({
        name: detailFg?.name,
        name_jp: itemJP?.name,
        description: detailFg?.description,
        description_jp: itemJP?.description,
        image_url: detailFg?.image_url,
        asset_url: detailFg?.storage_link,
        count: detailFg?.count,
        country_targets: detailFg?.country_targets  || [],
        specify_target_countries: detailFg?.country_targets?.length > 0,
        licensor: detailFg?.licensor?.name,
        project: detailFg?.licensor_id,
        serial_code: detailFg?.digital_figure_codes
      });
      setCheckedIds(detailFg?.country_targets  || []);
    } else {
      reset({
        country_targets: [],
      });
    }
  }, [reset, detailFg]);

  const handleCheckboxChangeAll = (isChecked) => {
    const newCheckedIds = isChecked ? countries.map(country => country.country_code) : [];
    setCheckedIds(newCheckedIds);
    setValue("country_targets", newCheckedIds);
    clearErrors("country_targets")
  }

    const selectedCountries = useMemo(() => {
      const countryItems = watch("country_targets") ? watch("country_targets") : [];
      return countryItems?.length > 0 ? countryItems : checkedIds;
    }, [watch("country_targets")]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description_jp"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Flex>
                )}
                <>
                  <FormUpload
                    label="Select image file to upload"
                    title={transLabel("image")}
                    name="image_url"
                    type='IMAGE'
                    disabled={disableWhenJP}
                    accept={"image/*"}
                    textButton={nameImage}
                    handleOnChange={(e) =>
                      handleImageChange(e, "image_url")
                    }
                    handleDragImage={(val) =>
                      handleDragImage(val, "image_url")
                    }
                    deleteImage={() => deleteImage("image_url")}
                    isRequired
                  />
                  {!watch("image_url") && (
                    <Text>*Currently support .png, .jpg less than 200KB </Text>
                  )}
                  <FormUpload
                    label="Select image file to upload"
                    title={transLabel("asset")}
                    name="asset_url"
                    type='IMAGE'
                    disabled={disableWhenJP}
                    accept=".png, .jpg, .jpeg, .fbx"
                    textButton={nameImage}
                    handleOnChange={(e) =>
                      handleImageChange(e, "asset_url")
                    }
                    handleDragImage={(val) =>
                      handleDragImage(val, "asset_url")
                    }
                    deleteImage={() => deleteImage("asset_url")}
                    isRequired
                    loading={loadingImg?.asset_url}
                  />
                  {!watch("asset_url") && (
                    <Text>*Currently support .fbx for 3D assets</Text>
                  )}
                  <Flex direction="column" gap="12px" w="100%" padding="8px 0">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("features")}
                    />
                    <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                      {FEATURES_LIST?.map((mode, index) => (
                        <FormCheckbox
                          key={index}
                          name={`${mode?.value}`}
                          label={mode.label}
                          isChecked={listModeType[mode.value]}
                          onChange={(e) => {
                            setListModeType(prev => ({
                              ...prev,
                              [mode.value]: e.target.checked 
                            }))
                            if (mode.value === 'is_holo_model' && e.target.checked === false) {
                              setValue("serial_code", null)
                            } else if (mode.value === 'is_holo_model' && e.target.checked === true) {
                              setValue("serial_code", [])
                              setValue("count", 0)
                            }
                          }}
                          wrapperProps={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0"
                          }}
                          disabled={disableWhenJP}
                      />)
                      )}
                    </Grid>
                  </Flex>
                  {listModeType?.is_holo_model && (
                    <Flex direction="column" gap={4} w="100%">
                      <FormLabelComponent
                        m="0px"
                        title={transLabel("serialCode")}
                        isRequired
                      />
                      <Flex direction="column" gap="6px">
                        <FormUpload
                          label="Select a file to upload"
                          name="csv"
                          handleOnChange={handleOnChangeCSV}
                          accept=".csv"
                        />
                        {errors?.serial_code?.message && <TextError >{errors?.serial_code?.message}</TextError>}
                        {fieldCSV?.length === 0 && (
                          <Text>*Currently support .csv </Text>
                        )}
                        {watch("serial_code")?.length > 0 && (
                          <Flex gap="4px" flexWrap='wrap'>
                            {watch("serial_code")?.map((code, index) => (
                              <Text key={index} p="6px 8px" borderRadius="16px" color="#fff" bg="#8bd5ff">
                                {code}
                              </Text>
                            ))}
                          </Flex>
                        )}
                        {fieldCSV?.map((item, index) => {
                          return (
                            <ItemCSV
                              key={item?.id}
                              name={item?.path}
                              isError={item?.isError}
                              indexItem={index}
                              removeCSV={() => {
                                reset({
                                  ...getValues(),
                                  serial_code: [],
                                  count: '0',
                                })                
                                removeCSV()
                              }}
                            />
                          );
                        })}
                      </Flex>
                    </Flex>
                  )}
                  <SelectGroupLicensor trans={trans} listLicensors={listLicensors} setValue={setValue} watch={watch}/>
                  <FormInput
                    wrapperProps={{
                      w: "49%",
                    }}
                    name="count"
                    label={transLabel("count")}
                    placeholder={transLabel("enterValue")}
                    type="number"
                    min={0}
                    isRequired
                    disabled={disableWhenJP || listModeType?.is_holo_model}
                  />
                  <Flex alignItems="center" gap="8px">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("targetCountry")}
                    />
                    <FormCheckbox
                      name={`specify_target_countries`}
                      wrapperProps={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0"
                      }}
                    />
                  </Flex>
                  {watch("specify_target_countries") && (
                    <>
                      <Flex
                        direction="column"
                        gap="12px"
                        w="100%"
                        padding="8px 0"
                      >
                        <Flex alignItems="center" gap="8px">
                          <FormLabelComponent
                            m="0px"
                            title={transLabel("countries")}
                            isRequired
                          />
                          <Text p="4px 10px" bg="#a7e6ff" borderRadius="20px">
                            {checkedIds?.length}/{countries?.length}{" "}
                            {transLabel("selected")}
                          </Text>
                        </Flex>
                        <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                          <FormCheckbox
                            name={`all_country`}
                            label={transLabel("all")}
                            wrapperProps={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0",
                            }}
                            isChecked={
                              selectedCountries?.length === countries.length &&
                              countries?.length > 0
                            }
                            onChange={(e) =>
                              handleCheckboxChangeAll(e.target.checked)
                            }
                          />
                        </Grid>
                      </Flex>
                      <FormInput
                        type="text"
                        label=""
                        placeholder={transLabel("allCountries")}
                        onClick={onOpenSelectCountry}
                        disabled={
                          (selectedCountries?.length === countries?.length &&
                            countries?.length > 0)
                        }
                        name="country_targets"
                        value=""
                        autoComplete="off"
                        renderIconSearch
                        isReadOnly
                      />
                    </>
                  )}
                </>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Digital Figure" : "New Digital Figure"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && (
          <Box px='24px'>
            Please check the form again in the Japanese section.
          </Box>
        )}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            {transLabel("cancel")}
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {transLabel("save")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSelectCountry && (
        <ModalSelectCountries
          isOpen={isOpenSelectCountry}
          onClose={onCloseSelectCountry}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          clearErrors={clearErrors}
          watch={watch}
          countries={countries}
        />
      )}
    </Modal>
  );
}
