export const PERMISSIONS = {
  /**
   * DASHBOARD
   */
  DASHBOARD: "DASHBOARD",

  /**
   * MACHINES
   */
  MACHINE_MAP: "MACHINE_MAP",

  MACHINE_SETTING: "MACHINE_SETTING",

  MACHINE_GAMEPLAY_TYPE: "MACHINE_GAMEPLAY_TYPE",

  /**
   * USER MANAGEMENT
   */
  USER_MANAGEMENT_USER_MANAGEMENT: "USER_MANAGEMENT_USER_MANAGEMENT",
  USER_MANAGEMENT_VERIFICATION_BADGE: "USER_MANAGEMENT_VERIFICATION_BADGE", 

  /**
   * GAME CONTROL
   */
  GAME_CONTROL_PRIZE: "GAME_CONTROL_PRIZE",

  GAME_CONTROL_EVENT: "GAME_CONTROL_EVENT",

  GAME_CONTROL_GAME_MODE: "GAME_CONTROL_GAME_MODE",

  /**
   * ADMIN ACCOUNT
   */
  ADMIN_ACCOUNT_LIST: "ADMIN_ACCOUNT_LIST",

  ADMIN_ACCOUNT_ROLE: "ADMIN_ACCOUNT_ROLE",

  /**
   * NFT MANAGEMENT
   */
  NFT_MANAGEMENT_HUNTER: "NFT_MANAGEMENT_HUNTER",

  NFT_MANAGEMENT_GAULET: "NFT_MANAGEMENT_GAULET",

  NFT_MANAGEMENT_BOUNTY_BALL: "NFT_MANAGEMENT_BOUNTY_BALL",

  NFT_MANAGEMENT_OWNER: "NFT_MANAGEMENT_OWNER",

  NFT_MANAGEMENT_COLLABS: "NFT_MANAGEMENT_COLLABS",

  NFT_MANAGEMENT_IMPORT: "NFT_MANAGEMENT_IMPORT",

  /**
   * GACHA MANAGEMENT
   */
  GACHA_MANAGEMENT: "GACHA_MANAGEMENT",

  /**
   * CONTACT
   */
  CONTACT: "CONTACT",

  /**
   * GAME PARAMS
   */
  GAME_PARAMS_LOGIN_REWARD: "GAME_PARAMS_LOGIN_REWARD",

  GAME_PARAMS_EXCHANGE_RATE: "GAME_PARAMS_EXCHANGE_RATE",

  GAME_PARAMS_FUSION_AND_AWAKEN: "GAME_PARAMS_FUSION_AND_AWAKEN",

  GAME_PARAMS_DAILY_REWARD_POOL: "GAME_PARAMS_DAILY_REWARD_POOL",

  GAME_PARAMS_TREASURE_BOX: "GAME_PARAMS_TREASURE_BOX",

  GAME_PARAMS_SHOP_PACKAGE: "GAME_PARAMS_SHOP_PACKAGE",

  GAME_PARAMS_LIFE_GAUGE_AND_ENERGY_GAUGE:
    "GAME_PARAMS_LIFE_GAUGE_AND_ENERGY_GAUGE",

  GAME_PARAMS_AP_DISTRIBUTION: "GAME_PARAMS_AP_DISTRIBUTION",

  GAME_PARAMS_XP_REWARD: "GAME_PARAMS_XP_REWARD",

  GAME_PARAMS_PLAYER_LEVEL: "GAME_PARAMS_PLAYER_LEVEL",

  GAME_PARAMS_PLAY_FEE_AND_TICKET: "GAME_PARAMS_PLAY_FEE_AND_TICKET",

  GAME_PARAMS_BOUTY_BALL_AND_GIMMICK: "GAME_PARAMS_BOUTY_BALL_AND_GIMMICK",

  GAME_PARAMS_HUNTER_COORDINATES: "GAME_PARAMS_HUNTER_COORDINATES",

  /**
   * PRESENT BOX MANAGEMENT
   */
  PRESENT_BOX_MANAGEMENT: "PRESENT_BOX_MANAGEMENT",

  /**
   * NOTICE_MANAGEMENT
   */
  NOTICE_MANAGEMENT: "NOTICE_MANAGEMENT",

  /**
   * DIGITAL MANAGEMENT
   */
  DIGITAL_MANAGEMENT_DIGITAL_FIGURE: "DIGITAL_MANAGEMENT_DIGITAL_FIGURE",

  DIGITAL_MANAGEMENT_DF_VIEW_BG: "DIGITAL_MANAGEMENT_DF_VIEW_BG",

  DIGITAL_MANAGEMENT_ROOM: "DIGITAL_MANAGEMENT_ROOM",

  DIGITAL_MANAGEMENT_ROOM_ITEM: "DIGITAL_MANAGEMENT_ROOM_ITEM",

  DIGITAL_MANAGEMENT_PARTNER: "DIGITAL_MANAGEMENT_PARTNER",

  /**
   * BANNER MANAGEMENT
   */
  BANNER_MANAGEMENT: "BANNER_MANAGEMENT",

  /**
   * SETTING
   */
  SETTING_SYSTEM: "SETTING_SYSTEM",

  SETTING_PEAK_TIME: "SETTING_PEAK_TIME",

  SETTING_ADMIN_WALLET: "SETTING_ADMIN_WALLET",

  SETTING_COIN_CONSUMPTION_PRIORITY: "SETTING_COIN_CONSUMPTION_PRIORITY",

  SETTING_TAX: "SETTING_TAX",

  SETTING_SERVICE_CHANGE: "SETTING_SERVICE_CHANGE",
};
