import { Flex, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { deleteLicensors, getLicensors } from "api/licensors.api";
import { masterCountries } from "api/notice";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ModalConfirm from "components/Modal/ModalConfirm";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { PERMISSIONS } from "constants/permissions";
import { usePermission } from "hooks/usePermission";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { convertStringSnake } from "utils/string";
import { ERROR_DIGITAL_FIGURE } from "../constants";
import ActionDigitalFigures from "./action";
import ConditionDigital from "./condition";
import CreateAndUpdateLicensor from "./CreateAndUpdateLicensor";
import LicensorsRow from "./row";

export default function Licensors() {
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.DIGITAL_MANAGEMENT_PARTNER
  );
  const { showToastSuccess } = useWithToast();
  const [isLoading, setIsLoading] = useState(false);
  const [licensors, setLicensors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [checkedIds, setCheckedIds] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    name: "",
  });

  const toast = useToast();
  const [modal, setModal] = useState({
    modalCreateAndEdit: false,
    modalDelete: false,
  });

  const labels = ["ACTION", "NO.", "LICENSOR", "PROJECT", "TARGET COUNTRIES"];
  const textColor = useColorModeValue("#000000", "white");

  const listLicentorCheckbox = useMemo(
    () => licensors?.filter((item) => item?.name !== "Bounty Hunter"),
    [licensors]
  );

  const handleCreateOrUpdateSuccess = () => {
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  };

  const toggle = (type, data) => {
    setModal((prev) => ({ ...prev, [type]: !prev[type] }));
    setDataDetail(data);
  };

  const getMasterCountries = useCallback(async () => {
    try {
      const res = await masterCountries();
      if (res?.data?.success) {
        const data = res?.data.data;
        setCountries(data);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  });

  const getListLicensors = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getLicensors(params);
      if (data?.success) {
        const res = data?.data;
        setLicensors(res?.records);
        setTotalPage(res?.total_page);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        const listDelete = checkedIds?.map((item) => item?.id);
        const res = await deleteLicensors(listDelete);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Delete Success",
          });
          toggle("modalDelete");
          setCheckedIds([]);
          setParams({
            ...params,
            page: 1,
          });
        } else {
          toast({
            description: convertStringSnake(
              ERROR_DIGITAL_FIGURE[res?.data?.message] ||
                ERROR_DIGITAL_FIGURE[res?.data?.messages[0]] ||
                res?.data?.messages[0]
            ),
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeCheckedAll = (isChecked) => {
    if (isChecked) {
      setCheckedIds(listLicentorCheckbox);
    } else {
      setCheckedIds([]);
    }
  };

  useEffect(() => {
    getListLicensors();
    getMasterCountries();
  }, [getListLicensors]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader
          px="22px"
          mb="12px"
          display="flex"
          flexDirection="column"
          gap="12px"
        >
          <Flex w="100%" gap={4}>
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Licensors
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            gap={6}
          >
            <ConditionDigital
              isLoading={isLoading}
              setParams={setParams}
              params={params}
              checkedIds={checkedIds}
            />
            {isHasPermissionEdit && (
              <ActionDigitalFigures checkedIds={checkedIds} toggle={toggle} />
            )}
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            dataRow={licensors}
            isCheck
            isChecked={
              listLicentorCheckbox?.length > 0 &&
              checkedIds.length === listLicentorCheckbox?.length
            }
            onChangeChecked={onChangeCheckedAll}
          >
            <>
              {licensors?.map((item, index) => {
                return (
                  <LicensorsRow
                    key={item?.id}
                    index={(params?.page - 1) * params?.limit + index + 1}
                    data={item}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    toggle={toggle}
                    isHasPermissionEdit={isHasPermissionEdit}
                    countries={countries}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {modal?.modalCreateAndEdit && (
        <CreateAndUpdateLicensor
          isOpen={modal.modalCreateAndEdit}
          onClose={() => toggle("modalCreateAndEdit", null)}
          countries={countries}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {modal?.modalDelete && (
        <ModalConfirm
          isOpen={modal?.modalDelete}
          onClose={() => toggle("modalDelete")}
          onSubmit={handleDelete}
        />
      )}
    </Flex>
  );
}
