import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getUsersManagement, resetBcoin, updateUser } from "api/userManagement";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import UserManagementRow from "components/Tables/user/userRow";
import { useCallback, useEffect, useState } from "react";
import ConditionUser from "./condition";
// import CreateUser from "./components/createUser";
import UserDetailComponent from "./components/detail";
// import EditUser from "./components/editUser";
// import FreePlayHistory from "./components/freePlay/freePlayHistory";
// import HistoryPlayGame from "./components/historyPlay";
// import PrizeHistory from "./components/prize/prizeHistory";
import ActionUser from "./action";
import { downloadCSV } from "utils/downloadCSV";
import ButtonType from "components/Button";
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalBanAndUnBan from "./modalBanAndUnban"
import ModalTransferCoin from "./ModalTransferCoin";
import ModalTransferTicket from "./modalTransferTicket"
import ModalTransferNft from "./modalTransferNft"
import DetailUser from "./detailUser";
import { STATUS_USER } from "./constant";
import { PERMISSIONS } from "constants/permissions";
import { usePermission } from "hooks/usePermission";

export default function UserManagement() {
  const labels = [
    "Action",
    "ID",
    "Name",
    "Wallet",
    "Status",
    "Level",
    "Rank",
    "B-Coin",
    "Treasure Box",
    "Sphere",
    "Orb",
    "Ticket",
    "Free Ticket",
    "Total Payment",
    "First Login At",
    "Last Login Type",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [userDetail, setUserDetail] = useState(null);
  const [modal, setModal] = useState({
    modalHaveBanned: false,
    modalTransferCoin: false,
    modalTransferTicket: false,
    modalTransferNft: false,
    confirmReset: false,
    confirmBan: false,
    confirmUnBan: false,
    showDetail: false
  })
  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setUserDetail(data)
    if (type === "showDetail") {
      setCheckedIds([]);
    }
  }
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.USER_MANAGEMENT_USER_MANAGEMENT
  );

  const {
    isOpen: isOpenDetailUser,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailUser,
  } = useDisclosure();

  const getUsers = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getUsersManagement({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setUsers(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  // detail user modal

  const handleOpenDetailUserModal = (item) => {
    setUserDetail(item);
    onOpenDetailUser(true);
  };

  const handleCloseDetailUserModal = () => {
    setUserDetail(null);
    onCloseDetailUser();
  };

  // ban and unban user
  const banAndUnbanUser = (user, statusUpdate) => {
    const userStatusChange = {
      status: statusUpdate,
    };
    return updateUser(user?.id, userStatusChange);
  };

  const handleBanAndUnBanListUser = useCallback(
    async (statusUpdate, type) => {
      try {
        setIsLoading(true);
        const promise = checkedIds?.map((item) => {
          return banAndUnbanUser(item, statusUpdate);
        });
        const res = await Promise.all(promise);
        if (res && res[0]?.data?.success) {
          getUsers();
          setCheckedIds([]);
          toast({
            title: "Update success.",
            description: "Update success.",
            status: "success",
            duration: 3000,
            position: "top",
          });
          toggle(type, userDetail)
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
    [checkedIds]
  );

  const handleResetBcoin = async () => {
    const listIds = checkedIds?.map((check) => check?.id)
    const dataSubmit = {
      user_ids: listIds,
    };
    try {
      const res = await resetBcoin(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Reset B-coin Successfully!",
          description: "Reset B-coin Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess()
        setCheckedIds([])
        toggle("confirmReset", userDetail)
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const downloadPlayer = async () => {
    try {
      const baseUrl = `users/export-csv`;
      await downloadCSV(baseUrl);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(users);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const filterChecked = (itemRemove) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== itemRemove?.id)
    setCheckedIds(dataFilter)
  }

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {modal?.showDetail ? (
        <DetailUser
          toggle={toggle}
          userDetail={userDetail}
          isHasPermissionEdit={isHasPermissionEdit}
        />
      ) : (
        <Card px="0px">
          <CardHeader px="24px" mb="12px" display="flex" flexDirection="column" gap="8px">
            <Flex
              w="100%"
              gap={4}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                User Management
              </Text>
              <ButtonType
                text="Download CSV"
                fontSize="12px"
                sizeIcon="8px"
                btnType="dark"
                onClick={downloadPlayer}
              />
            </Flex>
            {isHasPermissionEdit && (
              <ActionUser
                checkedIds={checkedIds}
                handleBanAndUnBanListUser={handleBanAndUnBanListUser}
                toggle={toggle}
              />
            )}
          </CardHeader>
          <CardHeader px="24px" mb="32px">
            <ConditionUser
              isLoading={isLoading}
              setParams={setParams}
              params={params}
            />
          </CardHeader>
          <CardBody overflowX="auto">
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={users?.length > 0 && checkedIds.length === users?.length}
              onChangeChecked={onChangeChecked}
              dataRow={users}
            >
              <>
                {users?.map((item) => {
                  return (
                    <UserManagementRow
                      key={item?.id}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      handleOpenDetailUserModal={handleOpenDetailUserModal}
                      item={item}
                      toggle={toggle}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
          <Paginate
            page={params?.page}
            pageCount={totalPage}
            limit={params.limit}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
          />
        </Card>
      )}
      {isOpenDetailUser && (
        <UserDetailComponent
          user={userDetail}
          isOpen={isOpenDetailUser}
          onClose={handleCloseDetailUserModal}
        />
      )}
      {modal?.modalHaveBanned && (
        <ModalConfirm
          title=""
          description="Please select only active users to perform this action."
          textOk="OK"
          isOpen={modal?.modalHaveBanned} 
          onClose={() => toggle("modalHaveBanned", userDetail)} 
          onSubmit={() => toggle("modalHaveBanned", userDetail)} 
          isShowCancel={false}
        />
      )}
      {modal?.confirmReset && (
        <ModalBanAndUnBan
          title="CONFIRM"
          description="Are you sure you want to reset the selected user(s) ?"
          isOpen={modal?.confirmReset} 
          checkedIds={checkedIds}
          onClose={() => toggle("confirmReset", userDetail)} 
          onSubmit={() => handleResetBcoin()} 
          filterChecked={filterChecked}
        />
      )}
      {modal?.confirmBan && (
        <ModalBanAndUnBan
          title="CONFIRM"
          description="Are you sure you want to ban the selected user(s) ?"
          isOpen={modal?.confirmBan} 
          checkedIds={checkedIds}
          onClose={() => toggle("confirmBan", userDetail)} 
          onSubmit={() => handleBanAndUnBanListUser(STATUS_USER.BANNED, "confirmBan")}
          filterChecked={filterChecked}
        />
      )}
      {modal?.confirmUnBan && (
        <ModalBanAndUnBan
          title="CONFIRM"
          description="Are you sure you want to unban the selected user(s) ?"
          isOpen={modal?.confirmUnBan} 
          checkedIds={checkedIds}
          onClose={() => toggle("confirmUnBan", userDetail)} 
          onSubmit={() => handleBanAndUnBanListUser(STATUS_USER.ACTIVE, "confirmUnBan")}
          filterChecked={filterChecked}
        />
      )}
      {modal?.modalTransferCoin && (
        <ModalTransferCoin
          isOpen={modal?.modalTransferCoin} 
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          filterChecked={filterChecked}
          onClose={() => toggle("modalTransferCoin", userDetail)} 
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          userDetail={userDetail}
        />
      )}
      {modal?.modalTransferTicket && (
        <ModalTransferTicket
          isOpen={modal?.modalTransferTicket} 
          checkedIds={checkedIds}
          filterChecked={filterChecked}
          onClose={() => toggle("modalTransferTicket", userDetail)} 
          setCheckedIds={setCheckedIds}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          userDetail={userDetail}
        />
      )}
      {modal?.modalTransferNft && (
        <ModalTransferNft
          isOpen={modal?.modalTransferNft} 
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          filterChecked={filterChecked}
          onClose={() => toggle("modalTransferNft", userDetail)} 
          userDetail={userDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
