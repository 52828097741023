export const TAX_TABLE_KEY = {
  REINVESTMENT_RATE_POINTS_TOTAL: "reinvestment_rate_points_total",
  CUMULATIVE_CONSUMPTION_POINTS: "cumulative_consumption_points",
  REINVESTMENT_RATE_POINTS_WEEKLY: "reinvestment_rate_points_weekly",
};

export const TAX_REDISTRIBUTION_TABLE = [
  {
    key: TAX_TABLE_KEY.REINVESTMENT_RATE_POINTS_TOTAL,
    title: "Points based on the reinvestment rate of BCoin(2)",
    labels: ["", "reinvestment rate", "points"],
    dataTable: [],
  },
  {
    key: TAX_TABLE_KEY.CUMULATIVE_CONSUMPTION_POINTS,
    title: "Points based on the total cumulative consumption",
    labels: ["", "CONSUMPTION (bcOIN2)", "points"],
    dataTable: [],
  },
  {
    key: TAX_TABLE_KEY.REINVESTMENT_RATE_POINTS_WEEKLY,
    title: "Points based on 7-day reinvestment ",
    labels: ["", "reinvestment rate", "points"],
    dataTable: [],
  },
];
