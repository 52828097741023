const END_POINT = {
  LOGIN: "auth/login",
  REGISTER: "auth/register",
  LOGOUT: "auth/logout",
  CHANGE_PASS: "users/change-password",
  RESET_PASSWORD: "auth/reset-password",
  DASHBOARD: "admin/dashboard",

  // users
  USER_PROFILE: "admin/me",
  GET_USER: "users",
  USER_PLAYING: "users/playing-history",
  POST_USER: "users",
  GET_USER_BY_ID: "users",
  PUT_USER_BY_ID: "users",
  DELETE_USER_BY_ID: "users",
  USER_WALLET: "users",

  // health-check
  GET_HEALTH_CHECK: "health-check",

  // machine
  GET_MACHINE: "machines",
  POST_MACHINE: "machines",
  GET_MACHINE_BY_ID: "machines",
  PUT_MACHINE_BY_ID: "machines",
  DELETE_MACHINE_BY_ID: "machines",

  // machine-group
  GET_MACHINE_GROUP: "machine-group",

  // play fee
  GET_PLAY_FEES: "play-fees",
  POST_PLAY_FEES: "play-fees",
  GET_PLAY_FEES_BY_ID: "play-fees",
  PUT_PLAY_FEES_BY_ID: "play-fees",
  DELETE_PLAY_FEES_BY_ID: "play-fees",

  // role
  ROLE: "roles",
  RULE: "rules",
  RULE_GROUP: "rules/rule-with-rule-group",

  // Revenue sharing
  GET_REVENUE_SHARINGS: "revenue-sharings",
  POST_REVENUE_SHARING: "revenue-sharings",
  GET_REVENUE_SHARING_BY_ID: "revenue-sharings",
  PUT_REVENUE_SHARING_BY_ID: "revenue-sharings",
  DELETE_REVENUE_SHARING_BY_ID: "revenue-sharings",

  // project-categories
  GET_PROJECT_CATEGORY: "project-categories",
  POST_PROJECT_CATEGORY: "project-categories",
  GET_PROJECT_CATEGORY_BY_ID: "project-categories",
  PUT_PROJECT_CATEGORY_BY_ID: "project-categories",
  DELETE_PROJECT_CATEGORY_BY_ID: "project-categories",

  // prize
  GET_PRIZE: "prizes",
  GET_PRIZE_GAME_MODE: "prizes/game-mode",
  GET_PRIZE_CATEGORY: "prizes/category-prizes",
  POST_PRIZE: "prizes",
  GET_PRIZE_BY_ID: "prizes",
  PUT_PRIZE_BY_ID: "prizes",
  DELETE_PRIZE_BY_ID: "prizes",
  GET_PRIZE_RARITY: "prizes/rarity",
  GET_PRIZE_TRAITS: "prizes/traits",
  TYPES_PRIZE: "prizes/prize-types",
  MODE_TYPES_PRIZE: "prizes/mode-types",
  PLAY_FEES: "play-fees",
  POST_UPLOAD_IMAGE_PRIZE: "upload",
  SELECT_PRIZE_BOUNTY_BALL: "nft-bounty-ball/select-prize-bounty-ball",
  SELECT_PRIZE_GAUNTLET: "nft-gauntlet/select-prize-gauntlet",

  // referral reward
  GET_REFERRAL_REWARD: "referral-rewards",
  POST_REFERRAL_REWARD: "referral-rewards",
  GET_REFERRAL_REWARD_BY_ID: "referral-rewards",
  PUT_REFERRAL_REWARD_BY_ID: "referral-rewards",
  DELETE_REFERRAL_REWARD_BY_ID: "referral-rewards",

  // reward
  GET_REWARD: "rewards",
  POST_REWARD: "rewards",
  GET_REWARD_BY_ID: "rewards",
  PUT_REWARD_BY_ID: "rewards",
  DELETE_REWARD_BY_ID: "rewards",

  GET_lIST_ROLE: "admin/roles",
  ADD_ROLE: "admin/addRole",
  EDIT_ROLE: "admin/editRole",
  DELETE_ROLE: "admin/deleteRole",

  COURSE_LIST: "admin/",
  COURSE: "admin/",
  CREATE_COURSE: "admin/",
  UPDATE_COURSE: "admin/",

  GET_MACHINE_SETTINGS: "machines",
  CHANGE_MACHINE_GROUP: "machines/change-machine-group",
  GAME_MODE: "game-mode",
  CATEGORY_PRIZE: "category-prize",
  MACHINE_GAME_MODE: "game-mode/machines",
  SKILL_RANK: "skill-rank",
  SKILL_POINT: "skill-point",
  LEVEL_REQUIRED: "level-required",
  EVENT: "events",
  EVENT_SKILL: "events/skill-rank",
  EVENT_STANDARD: "events/standard",
  STANDARD_LEADER_BOARD: "standard-leaderboards",
  SKILL_RANK_LEADER_BOARD: "skill-rank-leaderboards",
  EVENT_SIMPLE_RESOURCE: "events/user-simple-resource",
  EVENT_SEND_RESOURCE: "events/send-resource-to-user",
  EVENT_RULE: "events/rules",
  EVENT_WHITE_LIST: "events/whitelist",
  EVENT_TICKET: "user-system-tickets/provide-ticket",
  USER_EVENT: "users/event",
  CRANE_MACHINES: "crane-machines",
  SYSTEM_TICKETS: "system-tickets",
  ROOM_HISTORIES: "room-histories",

  GAMEPLAY_TYPE: "gameplay-types",

  // cabinet type
  CABINET_TYPE_TYPE: "cabinet-type",

  // maintenance
  GET_MAINTENANCE: "system-maintenances",
  POST_MAINTENANCE: "system-maintenances",
  GET_MAINTENANCE_BY_ID: "system-maintenances",
  PUT_MAINTENANCE_BY_ID: "system-maintenances",
  DELETE_MAINTENANCE_BY_ID: "system-maintenances",

  // nfts hunter
  PRODUCT_HUNTER: "product/hunters",
  PRODUCT: "product",
  PRODUCT_SELL_MULTIPLE: "product/sell-multiple",
  PRODUCT_SELL_SINGLE: "product/sell-single",
  PRODUCT_CANCEL_SELL: "product/cancel-sell",
  PRODUCT_TRANSFER: "product/transfer",

  NFT_CONTRACT: "nft-contract",

  NFT_HUNTER: "nft-hunter",
  NFT_HUNTER_SUMMARY: "nft-hunter/summary",
  NFT_HUNTER_CSV: "nft-hunter/export-csv",
  NFT_HUNTER_RE_ALLOCATE: "nft-hunter/allocation",

  NFT_GENESIS: "nft-genesis",
  NFT_GENESIS_SUMMARY: "nft-genesis/summary",
  NFT_GENESIS_CSV: "nft-genesis/export-csv",
  NFT_GENESIS_RE_ALLOCATE: "nft-genesis/allocation",
  NFT_GENESIS_PACKAGE: "nft-genesis-package",
  NFT_GENESIS_PACKAGE_ORDER_HISTORY: "nft-genesis-package/order-history",
  NFT_GENESIS_PACKAGE_ORDER_HISTORY_EXPORT_CSV:
    "nft-genesis-package/order-history/export-csv",
  NFT_GENESIS_REMOVE_FROM_PACKAGE: "nft-genesis/remove-from-package",
  NFT_GENESIS_ADD_INTO_PACKAGE: "nft-genesis/add-into-package",
  NFT_GENESIS_TRANSFER_TO_PACKAGE: "nft-genesis/update-package",

  HUNTER_OFF_CHAIN: "hunter-off-chain",

  NFT_GAUNTLET: "nft-gauntlet",
  NFT_GAUNTLET_SUMMARY: "nft-gauntlet/summary",
  NFT_GAUNTLET_CSV: "nft-gauntlet/export-csv",
  NFT_GAUNTLET_RE_ALLOCATE: "nft-gauntlet/allocation",

  NFT_BOUNTY_BALL: "nft-bounty-ball",
  NFT_BOUNTY_BALL_SUMMARY: "nft-bounty-ball/summary",
  NFT_BOUNTY_BALL_CSV: "nft-bounty-ball/export-csv",
  NFT_BOUNTY_BALL_RE_ALLOCATE: "nft-bounty-ball/allocation",

  NFT_OWNER: "nft-holder-revenue-monthly",
  NFT_OWNER_SUMMARY: "nft-holder-revenue-monthly/summary",
  NFT_OWNER_CSV: "nft-holder-revenue-monthly/export-csv",
  NFT_OWNER_IMPORT_CSV: "nft-holders/import-csv",
  NFT_OWNER_ACTIVE_NFTS: "nft-holders/active-nft",
  NFT_OWNER_DETAILS_CSV: "nft-holder-revenue-monthly/detail/export-csv",
  NFT_OWNER_CHANGE_TASK_STATUS: "nft-holder-revenue-monthly/change-task-status",

  MONTHLY_PAYOUT_SETTINGS: "monthly-payout-settings",
  MONTHLY_PAYOUT_SETTINGS_IMPORT_CSV: "monthly-payout-settings/import-csv",
  MONTHLY_PAYOUT_SETTINGS_MACHINE_QUANTITY: "monthly-payout-settings/machine-quantity",


  // rate
  EXCHANGE_RATE: "exchange-rate",
  EXCHANGE_RATE_CURRENCY: "exchange-rate/currency",

  // Licensors
  LICENSORS: "licensor",

  // admin
  GET_ADMIN: "admin",
  CREATE_ADMIN: "admin",
  UPDATE_ADMIN_PASSWORD: "admin/change-password",
  UPDATE_ADMIN: "admin/change-admin-role",
  CHANGE_ROLE: "change-admin-role",
  BLOCK_ADMIN: "admin/block-admins",
  UNBLOCK_ADMIN: "admin/unblock-admins",

  // wallet
  WALLET: "master-public-key",

  // contact
  CONTACT: "machine-contact",
  CONTACT_UPDATE_STATUS: "machine-contact/change-status/multiple-ids",

  //
  DIGITAL_FIGURE: "digital-figure",
  DIGITAL_FIGURE_ALLOCATION: "digital-figure/allocation",
  DIGITAL_FIGURE_ALLOCATION_TYPE: "digital-figure/allocation-type",
  DIGITAL_FIGURE_TRANSFER: "digital-figure/transfer",
  BACKGROUND: "background",
  BACKGROUND_ALLOCATION: "background/allocation",
  BACKGROUND_TRANSFER: "background/transfer",
  ROOM_ITEM_ALLOCATION: "room-item/allocation",
  ROOM_ITEM_TRANSFER: "room-item/transfer",
  ROOM_ITEMS: "room-item",
  ROOM_ITEMS_ALLOCATION: "room-item/allocation-type",
  ROOM_ITEMS_TRANSFER: "room-item/transfer",
  ROOM_ITEMS_ALLOCATION_TYPE: "room-item/allocation-type",
  ROOM_BG: "room-bg",
  ROOM_BG_ALLOCATION: "room-bg/allocation",
  ROOM_BG_TRANSFER: "room-bg/transfer",
  ROOM_BG_ALLOCATION_TYPE: "room-bg/allocation-type",
  PARTNER: "partner",
  ROOM_TYPE: "room-bg/room-item-type",
  ALLOCATION_TYPE: "background/allocation-type",

  // Iot device
  IOT_DEVICE: "iot-device/device",

  // Login bonus
  BONUS_PASS_PURCHASE_SETTING: "bonus-pass-purchase-setting",
  CONSECUTIVE_LOGIN_BONUS_SETTING: "consecutive-login-bonus-setting",
  CUMULATIVE_LOGIN_BONUS_SETTING: "cumulative-login-bonus-setting",
  START_DASH_LOGIN_BONUS_SETTING: "start-dash-login-bonus-setting",
  RELEASE_BONUS_SETTING: "release-bonus-setting",

  // Gacha
  NON_NFT_GACHA: "non-nft-item",
  GACHA: "gacha",
  REWARD_CONTAINER: "reward-container",
  REWARD_EXPORT: "reward-container/nft-export-csv",
  GACHA_HISTORY: "gacha-history",
  USER_GACHA_HISTORY: "user-gacha-history",
  GACHA_INCOMING: "gacha/in-coming",

  // Notices
  NOTICE: "news",
  NOTICE_CATEGORY: "master-news-category",
  MASTER_COUNTRY: "master-country",

  // Fusion
  FUSION: "fusion-awaken-config",

  // Present Box
  INVITATION_CARD: "invitation-card/incoming-event-cards",
  PRESENT_BOX: "present-box",
  EVENT_IN_COMING: "events/in-coming",
  EXIST_USER: "users/check-exist",
  USER_INFO: "users/simple-info",
  PRESENT_BOX_TRIGGER: "present-box/trigger",
  PRESENT_BOX_HISTORY: "user-present-box",
  LIST_NFT_BOUNTY_BALL: "present-box/list-nft-bounty-ball",
  LIST_NFT_GAUNTLET: "present-box/list-nft-gauntlet",
  LIST_NFT_HUNTER: "present-box/list-nft-hunter",
  // Invitation
  INVITATION: "invitation-card",
  
  // System
  SYSTEM_MAINTENANCE: "system-setting/maintenance",
  SYSTEM_MAIL: "system-setting/mail",
  SYSTEM_MAIL_TEMPLATE: "system-setting/mail-template",
  SYSTEM_MAIL_TEST: "system-setting/test-send-mail",
  SYSTEM_ADMIN_WALLET: "system-setting/admin-wallet",
  FEATURE_TOGGLE: "feature-toggle",
  INQUIRIES: "machine-contact-categories",
  TAX: "tax",

  // Coin Consumption Priority
  COIN_CONSUMPTION_PRIORITY: "coin-consumption-priority",

  // Ads
  BANNER: "banner",
  BANNER_EVENT_IN_COMING: "events/in-event-coming",
  
  // Summary
  HOURLY_SUMMARY: "dashboard/hourly-summary",
  EVENT_SUMMARY: "dashboard/event-summary",
  MACHINE_SUMMARY: "dashboard/machine-state-summary",
  SUMMARY: "dashboard/summary",
  PAYMENT_FINCODE: "payment-setting/info/fincode",
  PAYMENT_SLASH: "payment-setting/info/slash",

  // User KOL
  USER_KOL: "user-kol",

  // Params
  SHOP_PACKAGE: "shop-package",
  DAILY_REWARD_POOL: "daily-reward-pool-config",
  PLAYER_LEVEL: "level-required",
  AP_REWARD: "ap-reward",
  RARITY: "rarity",
  XP_REWARD_GAME_MODE: "xp-reward-game-mode",
  XP_REWARD_HUNTER: "xp-reward-hunter",
  HUNTER_AR_PARAM: "hunter-ar-param",
  TREASURE_BOX: "treasure-box",
};

export default END_POINT;
