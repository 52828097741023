import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export const getListNonNftGacha = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NON_NFT_GACHA}?${params}`);
};

export const createNonNftGacha = (data) => {
  return http.post(`${END_POINT.NON_NFT_GACHA}`, data);
};

export const updateNonNftGacha = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.NON_NFT_GACHA}/${idEdit}`, data, option);
};

export const deleteNonNftGacha = (data) => {
  return http.delete(`${END_POINT.NON_NFT_GACHA}`, data);
};

// gacha management

export const getListGacha = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GACHA}?${params}`);
};

export const createNewGacha = (data) => {
  return http.post(`${END_POINT.GACHA}`, data);
};

export const getDetailGacha = (id) => {
  return http.get(`${END_POINT.GACHA}/${id}`);
};

export const updateDetailGacha = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.GACHA}/${idEdit}`, data, option);
};

export const deleteGacha = (data) => {
  return http.delete(`${END_POINT.GACHA}`, data);
};

export const getGachaItemType = () => {
  return http.get(`${END_POINT.GACHA}/gacha-item-type`, );
};

export const getListStatusGacha = () => {
  return http.get(`${END_POINT.GACHA}/gacha-status`, );
};
// Gacha Reward 

export const getListRewardContainer = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.REWARD_CONTAINER}?${params}`);
};

export const getListRewardNftGacha = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.REWARD_CONTAINER}/list-nft-gacha?${params}`);
};

export const createRewardNftGacha = (data) => {
  return http.post(`${END_POINT.REWARD_CONTAINER}`, data);
};

export const getDetailRewardNftGacha = (id) => {
  return http.get(`${END_POINT.REWARD_CONTAINER}/${id}`);
};

export const updateDetailRewardNftGacha = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.REWARD_CONTAINER}/${idEdit}`, data, option);
};

export const deleteDetailRewardNftGacha = (data) => {
  return http.delete(`${END_POINT.REWARD_CONTAINER}`, data);
};

export const importCSVRewardNft = (data) => {
  return http.post(`${END_POINT.REWARD_CONTAINER}/nft-import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

// History 
export const getGachaHistorySummary = () => {
  return http.get(`${END_POINT.USER_GACHA_HISTORY}/gacha-summary`);
};

export const getGachaHistory = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_GACHA_HISTORY}/gacha-item-history?${params}`);
};

export const getDetailGachaHistorySummary = (id) => {
  // const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_GACHA_HISTORY}/gacha-summary?gacha_id=${id}`);
};

export const getDetailGachaHistory = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_GACHA_HISTORY}/gacha-item-history/${id}?${params}`);
};

export function getGachaInComing() {
  return http.get(`${END_POINT.GACHA_INCOMING}`);
}


